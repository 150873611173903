const supportedLngs = ['en', 'es'];

export const ni18nConfig = {
    supportedLngs,
    ns: ['translation'],
}

export const DEFAULT_LANG = 'es';

export const FLAGS = {
    'es' : 'US',
    'en' : 'MX'
}