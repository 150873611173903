import React, { Component } from "react"
import { DEFAULT } from "../../../config"
import { connect } from "react-redux"

import { Grid, Button, Form, Input, TextArea } from "semantic-ui-react"

import { Modal } from "react-bootstrap"

import { toast } from "react-toastify"

import { handleShowContactModal, handleShowMediaModal, handleSetLoading } from "../../../actions/mediaAction"
import swal from 'sweetalert';
import { withTranslation } from 'react-i18next';

class ContactModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            phoneNumber: "",
            message: "",
            requiredField: {
                name: false,
                email: false,
                phoneNumber: false,
            },
        }

        this.closeContactModal = this.closeContactModal.bind(this)
        this.send = this.send.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.formatPhoneNumber = this.formatPhoneNumber.bind(this)
        this.validateEmail = this.validateEmail.bind(this)
        this.isEmptyOrSpaces = this.isEmptyOrSpaces.bind(this)
        this.validateForm = this.validateForm.bind(this)
        this.resetForm = this.resetForm.bind(this)
        this.notifySuccess = this.notifySuccess.bind(this)
        this.notifyError = this.notifyError.bind(this)
    }

    componentDidMount() {}

    notifySuccess (message) { toast.success(message, { position: toast.POSITION.BOTTOM_CENTER }) }
    notifyError (message) { toast.error(message, { position: toast.POSITION.BOTTOM_CENTER }) }

    send() {
        let success = true
        for (let index of Object.keys(this.state)) {
            if (index === "requiredField") continue
            success = this.validateForm(index)
            if (!success) break
        }
        
        if (success) {
            this.props.dispatch(handleShowContactModal(false))
            this.props.dispatch(handleSetLoading(true))
            let ids = []
            if (this.props.media.currentMedium) {
                ids = [this.props.media.currentMedium.id]
            } else {
                ids = this.props.media.favoriteMediaList.map(
                    medium => medium.id
                )
            }
            const data = {
                name: this.state.name,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
                message: this.state.message,
                media: ids,
            }
            DEFAULT.axios.post(`${DEFAULT.api}/api/contact`, data).then(res => {
                this.closeContactModal()
                this.resetForm()
                // this.notifySuccess('Datos enviados correctamente, a la brevedad nos estaremos comunicando contigo.')
                swal({
                    title: "Hemos recibido tus datos",
                    text: "En un momento un asesor se pondrá en contacto contigo.",
                    icon: "success"
                });
                this.props.dispatch(handleSetLoading(false))
            }).catch(error => {
                //this.notifyError('Error al enviar los datos, vuelva intentarlo mas tarde.')
                swal({
                    title: "Error al enviar los datos",
                    text: "Vuelve a intentarlo más tarde",
                    icon: "error"
                });
                this.closeContactModal()
                this.props.dispatch(handleShowMediaModal(true))
                this.props.dispatch(handleSetLoading(false))
            })
        }
    }

    handleOnChange(e) {
        let value = e.target.value
        if (e.target.name === "phoneNumber")
            value = this.formatPhoneNumber(value)
        this.setState({ [e.target.name]: value })
    }

    closeContactModal() {
        this.props.dispatch(handleShowContactModal(false))
    }

    resetForm() {
        this.setState({
            name: "",
            email: "",
            phoneNumber: "",
            message: "",
        })
    }

    validateForm(name) {
        let isOk = true
        switch (name) {
            case "name":
                isOk = !this.isEmptyOrSpaces(this.state.name)
                this.setState(prevState => ({
                    requiredField: {
                        ...prevState.requiredField,
                        name: !isOk,
                    },
                }))
                return isOk
            case "email":
                isOk = this.validateEmail(this.state.email)
                this.setState(prevState => ({
                    requiredField: {
                        ...prevState.requiredField,
                        email: !isOk,
                    },
                }))
                return isOk

            case "phoneNumber":
                isOk = !this.isEmptyOrSpaces(this.state.phoneNumber)
                this.setState(prevState => ({
                    requiredField: {
                        ...prevState.requiredField,
                        phoneNumber: !isOk,
                    },
                }))
                return isOk
            default:
                return isOk
        }
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase())
    }

    isEmptyOrSpaces(str) {
        return str === null || str.match(/^ *$/) !== null
    }

    formatPhoneNumber(str) {
        //Filter only numbers from the input
        let cleaned = ("" + str).replace(/\D/g, "")

        //Check if the input is of correct length
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

        if (match) {
            return "(" + match[1] + ") " + match[2] + "-" + match[3]
        }

        return str
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <Modal
                    size="md"
                    centered
                    show={this.props.media.showContactModal}
                    onHide={this.closeContactModal}
                    scrollable
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t("contact_modal.contact")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Grid columns={2} centered>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Input
                                            required
                                            label={t("contact_modal.name")}
                                            error={
                                                this.state.requiredField.name
                                            }
                                            name="name"
                                            placeholder="Juan Perez"
                                            value={this.state.name}
                                            onChange={this.handleOnChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Input
                                            required
                                            label={t("contact_modal.phone")}
                                            name="phoneNumber"
                                            placeholder="(443) 314 5578"
                                            error={
                                                this.state.requiredField.phoneNumber
                                            }
                                            value={this.state.phoneNumber}
                                            onChange={this.handleOnChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={12}>
                                        <Form.Field
                                            required
                                            control={Input}
                                            label={t("contact_modal.email")}
                                            name="email"
                                            placeholder="joe@naranti.com.mx"
                                            error={
                                                this.state.requiredField.email
                                            }
                                            value={this.state.email}
                                            onChange={this.handleOnChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Form.Field
                                            control={TextArea}
                                            label={t("contact_modal.message")}
                                            name="message"
                                            value={this.state.message}
                                            onChange={this.handleOnChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            color="black"
                            onClick={this.closeContactModal}
                            className="mr-auto"
                            content={t("contact_modal.close")}
                        />
                        <Button
                            color="green"
                            icon="send"
                            labelPosition="right"
                            onClick={this.send}
                            content={t("contact_modal.send")}
                        />
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(withTranslation()(ContactModal))
