import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store/';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import {CommonService} from './services/common.service'; // Asegúrate de tener este servicio

// Configuración inicial de i18n
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: require('./locales/en.json'),
      },
      es: {
        translation: require('./locales/es.json'),
      },
    },
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false,
    },
  });

const Root = () => {
  const [isLanguageSet, setIsLanguageSet] = useState(false);

  useEffect(() => {
    const setLanguageFromService = async () => {
      try {
        const language = await CommonService.getLanguage();
        i18n.changeLanguage(language || 'es');
        setIsLanguageSet(true);
      } catch (error) {
        console.error('Error al obtener el idioma:', error);
        setIsLanguageSet(true);
      }
    };

    setLanguageFromService();
  }, []);

  if (!isLanguageSet) {
    return <div>Cargando...</div>;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  );
};

ReactDOM.render(
  <Root />,
  document.getElementById('root')
);

// Para los service workers
serviceWorker.unregister();
