import React, { Component } from "react"
import { DEFAULT } from "../../../config"
import { connect } from "react-redux"
import { Map, GoogleApiWrapper } from "google-maps-react"
import Markers from "../../../media/Images"
import programador from "../../../media/images/programador.svg"

import MarkerClusterer from "@google/markerclustererplus"

import {
    handleFetchMedia,
    handleFetchFilters,
    handleSetFavoriteMedium,
    handleShowMediaModal,
    handleSetCurrentMedium,
    handleSetMarkers,
    handleSetMarkerClusterer,
    handleSetMapCircle,
    handleSetMap,
    // handleSetLoading,
} from "../../../actions/mediaAction"
import { countries } from "../../../utils/countries"
// import { country_settings } from "../../../utils/country_settings"
import {
    Responsive,
} from "semantic-ui-react"
import { withTranslation } from 'react-i18next';

class MapContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModalDesc: false,
            currentMedium: null,
            initialCenter: {
                lat: 22.5650849,
                lng: -103.5364406,
            },
            zoom: 6,
            minZoom: 5,
            showMarkers: 0,
            markerClusterer: null,
            trafficLayer: new this.props.google.maps.TrafficLayer(),
            trafficShow: false,
            infoWindow: new this.props.google.maps.InfoWindow(),
            country: this.props.country,
            markers: [],
            renderMarkersAttemps: 0,
            mediaFiltered: []
        }
        this.renderMarkerCluster = this.renderMarkerCluster.bind(this)
        this.getMarker = this.getMarker.bind(this)
        this.showMediaModal = this.showMediaModal.bind(this)
        this.drawAdvertisingCircles = this.drawAdvertisingCircles.bind(this)
        this.drawMarkerGroup = this.drawMarkerGroup.bind(this)
        this.hideShowCircles = this.hideShowCircles.bind(this)
        this.getPosition = this.getPosition.bind(this)
        this.getCountryAndMoveToOnMap = this.getCountryAndMoveToOnMap.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(handleFetchFilters())
        this.getCountryAndMoveToOnMap()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.mediaFiltered.length !== this.props.media.mediaFiltered.length) {
            // console.log(prevState.mediaFiltered.length)
            // console.log(this.props.media.mediaFiltered.length)
            this.setState({mediaFiltered: this.props.media.mediaFiltered})
            this.renderMarkerCluster()
        }
    }

    getCountryAndMoveToOnMap () {
        const self = this
        const map = this.refs.map.map
        if ("geolocation" in navigator && this.state.country === undefined) {
            navigator.geolocation.getCurrentPosition(function(position) {
                const result = new window.google.maps.Geocoder().geocode({
                    location: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    }
                })
                result.then(res => {
                    const results = res.results
                    if(results[0]) {
                        const location = results[0]
                        const country = location.address_components.find(component => component.types.includes("country"))
                        if (country) {
                            var request = {
                                location: map.getCenter(),
                                query: `country  ${country.long_name}`,
                                type: 'country'
                            }
                            
                            self.setState({markers: []})
                            self.props.setCountry(country.short_name)
                            self.props.dispatch(handleFetchMedia(country.short_name))
                            const service = new window.google.maps.places.PlacesService(map)
                            if(self.props.countryHasSettings(country.short_name)) {
                                service.textSearch(request, (places, status) => {
                                    const countryPlace = places.find(place => place.types.includes("country"))
                                    if(countryPlace) {
                                        map.panTo(countryPlace.geometry.location)
                                        self.renderMarkerCluster()
                                    } else {
                                        console.log("Country not found");
                                    }
                                })
                            } else {
                                console.log("country without settings")
                            }
                        } else {
                            console.log("Country not found");
                        }
                    }
                })
            })
        } else {
            // console.log("Not Available");
        }
        
        if (this.state.country !== undefined) {
            const country = this.state.country.toUpperCase()
            self.props.setCountry(country)
            self.props.dispatch(handleFetchMedia(country))
            const countryName = countries[country] ? countries[country] : 'México'
            var request = {
                location: map.getCenter(),
                query: `country  ${countryName}`,
                type: 'country'
            }
    
            const service = new window.google.maps.places.PlacesService(map)
            
            service.textSearch(request, (places, status) => {
                const country = places.find(place => place.types.includes("country"))
                if(country) {
                    map.panTo(country.geometry.location)
                } else {
                    console.log("Country not found");
                }
            })
        } else {
            const defaultCountry = 'MX'
            self.props.setCountry(defaultCountry)
            self.props.dispatch(handleFetchMedia(defaultCountry))
            const countryName = countries[defaultCountry] ? countries[defaultCountry] : 'México'
            var request = {
                location: map.getCenter(),
                query: `country  ${countryName}`,
                type: 'country'
            }
    
            const service = new window.google.maps.places.PlacesService(map)
            
            service.textSearch(request, (places, status) => {
                const country = places.find(place => place.types.includes("country"))
                if(country) {
                    map.panTo(country.geometry.location)
                } else {
                    console.log("Country not found");
                }
            })
        }
        
    }

    renderMarkerCluster()  {
        const media = this.state.mediaFiltered;
        if (media.length === 0) {
            return;
        }
        // if (media.length === 0 && this.state.renderMarkersAttemps < 10) {
        //     this.setState({renderMarkersAttemps: this.state.renderMarkersAttemps+1})
        //     window.setTimeout(this.renderMarkerCluster, 2000);
        //     return;
        // } else {
        //     this.props.dispatch(handleSetLoading(false))
        // }
        let markers = []
        markers = media.map(this.getMarker)
        this.setState({markers: markers})
        // if (this.state.markers.length <= 0) {
            
        // } else {
            
        //     markers = this.state.markers
        // }
        
        const programadorMarker = new this.props.google.maps.Marker({
            icon: {
                url: programador,
                anchor: new this.props.google.maps.Point(16, 16),
                scaledSize: new this.props.google.maps.Size(24, 24),
            },
            position: this.getPosition(),
            title: "Develop by E.G."
        });
        programadorMarker.addListener('click', () => {
            const infoWindow = new this.props.google.maps.InfoWindow({
                content: "Hola, soy el programador que realizó esta plataforma, pronto me verás por otro lado del mundo."
            });
            infoWindow.open(this.props.media.map, programadorMarker);
        });
    
        const allMarkers = [programadorMarker, ...markers];
    
        this.props.dispatch(handleSetMarkers(allMarkers));
        
        if (this.state.markerClusterer) {
            this.state.markerClusterer.clearMarkers();
            this.state.markerClusterer.addMarkers(allMarkers);
            this.drawAdvertisingCircles(allMarkers);
        }
    }

    showMediaModal() {
        this.props.dispatch(handleShowMediaModal(true))
    }

    onMarkerClick(marker, medium) {
        this.props.dispatch(handleSetCurrentMedium(medium))
        this.showMediaModal()
    }

    getMarker(medium) {
        const type = medium.type.original.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
        let iconType = medium.type.original_glo ? medium.type.original_glo : medium.type.original
        iconType = iconType.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
    
        const icon = {
            url: Markers[iconType],
            anchor: new this.props.google.maps.Point(16, 16),
            scaledSize: new this.props.google.maps.Size(38, 34),
        };
    
        const latlng = new this.props.google.maps.LatLng(
            medium.lattitude,
            medium.longitude
        );
    
        const marker = new this.props.google.maps.Marker({
            icon: icon,
            position: latlng,
            title: type,
            code: medium.full_code
        });

        this.props.google.maps.event.addListener(marker, "click", () => {
            this.onMarkerClick(marker, medium);
        });
    
        return marker;
    }

    handleAddToFavorite(medium) {
        this.props.dispatch(handleSetFavoriteMedium(medium))
    }

    drawAdvertisingCircles(markers = null) {
        // console.log("setting advertising circles")
        if (markers) {
            const markerMap = {};
            const filteredMarkers = [];
            markers.sort((a, b) => a.position.lat() - b.position.lat())
            for (const marker of markers) {
                const key = `${marker.position.lat()}_${marker.position.lng()}`
    
                if (markerMap[key]) {
                    filteredMarkers.push(marker)
                }
                markerMap[key] = true
            }
            filteredMarkers.sort((a, b) => a.position.lat() - b.position.lat())
            this.drawMarkerGroup(filteredMarkers)
        }
        // console.log("advertising circles done")
    }
    
    drawMarkerGroup(markers) {
        const circleOffsets = [
            { lat: 0.00015, lng: 0.00015 },
            { lat: -0.00015, lng: -0.00015 },
            { lat: 0.00015, lng: -0.00015 },
            { lat: -0.00015, lng: 0.00015 },
            { lat: -0.0002, lng: 0.0002 },
            { lat: -0.0002, lng: -0.0002 },
            { lat: 0.0002, lng: -0.0002 },
            { lat: 0.0002, lng: 0.0002 },
            { lat: -0.00009, lng: 0.00009 },
            { lat: 0.00009, lng: -0.00009 },
            { lat: -0.00009, lng: -0.0002 },
            { lat: -0.00009, lng: 0.0002 },
            { lat: 0.00009, lng: 0.0002 },
            { lat: 0.00009, lng: -0.0002 },
            { lat: -0.0002, lng: -0.00009 },
            { lat: 0.0002, lng: -0.00009 },
            { lat: -0.0002, lng: 0.00009 },
            { lat: 0.0002, lng: 0.00009 },
            { lat: -0.0001, lng: 0.0002 },
            { lat: -0.0001, lng: -0.0002 },
            { lat: 0.0001, lng: 0.0002 },
            { lat: 0.0001, lng: -0.0002 }
            // Agrega más offsets si es necesario para separar más marcadores
        ];
    
        let equals = 0;
        let referenceMarker = markers[0];
    
        for (let index = 1; index < markers.length; index++) {
            const marker = markers[index];
            if (referenceMarker.position.equals(marker.position)) {
                const offset = circleOffsets[equals % circleOffsets.length];
                if (equals == 0) {
                    const advertisingCircle = new this.props.google.maps.Circle({
                        strokeColor: "#F88D2B",
                        strokeOpacity: 0.5,
                        strokeWeight: 2,
                        fillColor: "#F88D2B",
                        fillOpacity: 0.35,
                        map: this.refs.map.map,
                        center: referenceMarker.position,
                        radius: 25
                    });
                    this.props.dispatch(handleSetMapCircle(advertisingCircle));
                }
    
    
    
                marker.position = new this.props.google.maps.LatLng(
                    Number(referenceMarker.position.lat()) + offset.lat,
                    Number(referenceMarker.position.lng()) + offset.lng
                );
    
                equals++;
            } else {
                referenceMarker = marker;
                equals = 0;
            }
        }
    }    
    
    

    hideShowCircles(show) {
        if (this.props.media.mapCircleList) {
            this.props.media.mapCircleList.forEach((circle) => {
                if (show === circle.getVisible()) return false
                circle.setVisible(show)
            })
        }
    }

    getPosition() {
        let date = (new Date()).getTime()
        switch(date) {
            case date < (new Date(2020, 12, 25)).getTime():
                return new this.props.google.maps.LatLng(
                    -73.9499962,
                    -121.0
                )
            case date >= (new Date(2020, 12, 25, 0)).getTime() && date <= (new Date(2021, 1, 1, 0)).getTime():
                return new this.props.google.maps.LatLng(
                    -73.9499962,
                    -42.872449
                )
            case date >= (new Date(2021, 1, 1, 0)).getTime():
                return new this.props.google.maps.LatLng(
                    66.178962, 
                    100.062345
                )
            default:
                return new this.props.google.maps.LatLng(
                    -73.9499962,
                    -121.0
                )
        }
    }

    render() {
        const {t} = this.props
        let handleOnZoomChanged = (e, map) => {
            // this.drawAdvertisingCircles()
            // const showByZoom = 18
            if (map.getZoom() >= 18) this.hideShowCircles(true)
            else this.hideShowCircles(false)
            // if (this.state.markerClusterer) {
            //     //Test to get the clusters
            //     let clusters = this.state.markerClusterer.getClusters()
            //     console.log(clusters)
            //     clusters = clusters.map(va => va)
            //     console.log(clusters)
            // }
            // if (map.getZoom() >= showByZoom) {
            // }
            // if (map) {
            //   console.log(map)
            //   console.log(map.getZoom())
            //   if (map.getZoom() < showByZoom) {
            //     this.setState({showMarkers: 0})
            //   } else if (map.getZoom() >= showByZoom) {
            //     this.setState({showMarkers: 1})
            //   }
            // }
        }

        let trafficControl = (controlDiv, map) => {
            // Set CSS for the control border.
            const controlUI = document.createElement("div")
            controlUI.style.backgroundColor = "#fff"
            controlUI.style.border = "2px solid #fff"
            controlUI.style.borderRadius = "3px"
            controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)"
            controlUI.style.cursor = "pointer"
            controlUI.style.marginBottom = "22px"
            controlUI.style.textAlign = "center"
            controlUI.title = "Click to recenter the map"
            controlDiv.appendChild(controlUI)
          
            // Set CSS for the control interior.
            const controlText = document.createElement("div")
            controlText.style.color = "rgb(25,25,25)"
            controlText.style.fontFamily = "Roboto,Arial,sans-serif"
            controlText.style.fontSize = "16px"
            controlText.style.lineHeight = "38px"
            controlText.style.paddingLeft = "5px"
            controlText.style.paddingRight = "5px"
            controlText.innerHTML = t("map.show_traffic")
            controlUI.appendChild(controlText)

            if(controlUI){
                // Setup the click event listeners: simply set the map to Chicago.
                controlUI.addEventListener("click", () => {
                    var trafficLayer = this.state.trafficLayer
                    this.setState({trafficShow: !this.state.trafficShow})
                    if(this.state.trafficShow) {
                        controlText.innerHTML = t("map.hide_traffic")
                        trafficLayer.setMap(map)
                    } else {
                        controlText.innerHTML = t("map.show_traffic")
                        trafficLayer.setMap(null)
                    }
                });
            }
          
        }

        let measureControl = (controlDiv, map) => {
            let measureMarkers = []
            let mearureLines = []
            let clearMeasureMarkers = false
            let totalDistance = 0
            let marketID = 0
            // Set CSS for the control border.
            const controlUI = document.createElement("div")
            controlUI.style.backgroundColor = "#fff"
            controlUI.style.border = "2px solid #fff"
            controlUI.style.borderRadius = "3px"
            controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)"
            controlUI.style.cursor = "pointer"
            controlUI.style.marginBottom = "22px"
            controlUI.style.textAlign = "center"
            controlUI.title = "Click to recenter the map"
            controlDiv.appendChild(controlUI)
          
            // Set CSS for the control interior.
            const controlText = document.createElement("div")
            controlText.style.color = "rgb(25,25,25)"
            controlText.style.fontFamily = "Roboto,Arial,sans-serif"
            controlText.style.fontSize = "16px"
            controlText.style.lineHeight = "38px"
            controlText.style.paddingLeft = "5px"
            controlText.style.paddingRight = "5px"
            controlText.innerHTML = t("map.measure_distance")
            controlUI.appendChild(controlText)
          
            if(controlUI){
                controlUI.addEventListener("click", () => {
                    this.props.google.maps.event.addListener(map, 'click', function(event) {
                        placeMarker(event.latLng);
                    });
                    controlText.innerHTML = `${totalDistance.toFixed(2)} KM`
                    if (clearMeasureMarkers) {
                        this.props.google.maps.event.clearInstanceListeners(map, 'click')
                        measureMarkers.forEach(marker => {
                            marker.setMap(null)
                        })
                        mearureLines.forEach(line => {
                            line.setMap(null)
                        })
                        mearureLines = []
                        measureMarkers = []
                        controlText.innerHTML = t("map.measure_distance")
                    }
                    clearMeasureMarkers = !clearMeasureMarkers
                    // var trafficLayer = this.state.trafficLayer
                    // this.setState({trafficShow: !this.state.trafficShow})
                    // if(this.state.trafficShow) {
                    //     controlText.innerHTML = "Ocultar tráfico"
                    //     trafficLayer.setMap(map)
                    // } else {
                    //     controlText.innerHTML = "Mostrar tráfico"
                    //     trafficLayer.setMap(null)
                    // }
                });
            }
            // Setup the click event listeners: simply set the map to Chicago.

            const placeMarker = (location) => {
                const measureMarker = new this.props.google.maps.Marker({
                    position: location, 
                    map: map,
                    draggable:true,
                    id: marketID++
                })
                measureMarker.addListener('drag', () => {
                    reloadLines()
                })
                measureMarker.addListener('click', (event) => {
                    let indexMarker = 0
                    measureMarkers.forEach((measureMarker, index) => {
                        if(measureMarker.position.lat() === event.latLng.lat() && measureMarker.position.lng() === event.latLng.lng())
                            indexMarker = index
                    })
                    measureMarkers[indexMarker].setMap(null)
                    measureMarkers.splice(indexMarker, 1)
                    reloadLines()
                })
                measureMarkers.push(
                    measureMarker
                )
                reloadLines()
            }

            const reloadLines = () => {
                if (mearureLines.length) {
                    mearureLines.forEach(line => {
                        line.setMap(null)
                    })
                }
                mearureLines = []
                totalDistance = 0
                if (measureMarkers.length > 1) {
                    let coords = []
                    measureMarkers.forEach(marker => {
                        coords.push(marker.position)
                    })
                    measureMarkers.sort((markerOne, markerTwo) => {
                        totalDistance += parseFloat(calculateDistance(markerOne.position, markerTwo.position))
                    })
                    var line = new this.props.google.maps.Polyline({
                        path: coords,
                        geodesic: true,
                        strokeColor: '#FF0000',
                        strokeOpacity: 1.0,
                        strokeWeight: 2
                    })
                    mearureLines.push(line)
                    line.setMap(map)
                }
                controlText.innerHTML = `${totalDistance.toFixed(2)} KM`
            }

            var rad = function(x) {
                return x * Math.PI / 180;
            }

            const calculateDistance = (p1, p2) => {
                return (this.props.google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / 1000).toFixed(2);
            }
        }

        let handleOnReady = (mapProps, map) => {
            if (this.refs.map) {
                this.props.dispatch(handleSetMap(this.refs.map.map))
                let media = this.props.media.mediaFiltered
                
                let markers = []
                if (this.state.markers <= 0) {
                    markers = media.map(this.getMarker)
                    this.setState({markers: markers})
                } else {
                    markers = this.state.markers
                }
                
                let markerClusterer = new MarkerClusterer(
                    this.refs.map.map,
                    markers,
                    {
                        // imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                        styles: [
                            MarkerClusterer.withDefaultStyle({
                                width: 54,
                                height: 54,
                                url: Markers.markerClusterer,
                                textColor: "white",
                            }),
                        ],
                        ignoreHidden: true,
                        // calculator: (markers, clusterIconStylesCount) => {
                        //   let total = markers.length
                        //   let index = 0
                        //   while (total !== 0) {
                        //     //Create a new total by dividing by a set number
                        //     total = parseInt(total / 5, 10)
                        //     //Increase the index and move up to the next style
                        //     index++
                        //   }
                        //   return {
                        //     text: markers.length,
                        //     index: index
                        //   }
                        // }
                    }
                )
                this.setState({ markerClusterer: markerClusterer })
                this.state.markerClusterer.setMaxZoom(17)
                this.props.dispatch(handleSetMarkerClusterer(markerClusterer))
                this.renderMarkerCluster()
                const trafficControlDiv = document.createElement("div")
                trafficControl(trafficControlDiv, map)
                map.controls[this.props.google.maps.ControlPosition.BOTTOM_LEFT].push(trafficControlDiv)

                const measureControlDiv = document.createElement("div")
                measureControl(measureControlDiv, map)
                map.controls[this.props.google.maps.ControlPosition.RIGHT_CENTER].push(measureControlDiv)
                
            }
        }


        return (
            <>
                <Responsive maxWidth={499}>
                    <Map
                        ref="map"
                        google={window.google}
                        initialCenter={this.state.initialCenter}
                        zoom={this.state.zoom}
                        minZoom={this.state.minZoom}
                        streetViewControl={true}
                        onZoomChanged={handleOnZoomChanged}
                        onReady={handleOnReady}
                        fullscreenControl={false}
                        style={{
                            width: "100%",
                            height: '83vh'
                        }}
                    ></Map>
                </Responsive>
                <Responsive minWidth={500}>
                    <Map
                        ref="map"
                        google={window.google}
                        initialCenter={this.state.initialCenter}
                        zoom={this.state.zoom}
                        minZoom={this.state.minZoom}
                        streetViewControl={true}
                        onZoomChanged={handleOnZoomChanged}
                        onReady={handleOnReady}
                        fullscreenControl={false}
                        style={{
                            width: "100%",
                            height: '92vh'
                        }}
                    ></Map>
                </Responsive>
                
            </>
        )
    }
}

const mapStateToProps = (state) => state

export default connect(mapStateToProps)(
    GoogleApiWrapper({
        apiKey: DEFAULT.MAPS_API_KEY,
        libraries: ["places"],
    })(withTranslation()(MapContainer))
)
