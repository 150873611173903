import { DEFAULT_LANG } from '../ni18n.config';

class CommonService {

    static getLanguage() {
        
        const locale = 
            typeof window !== 'undefined' && window.localStorage.getItem('LANG');

        if (!locale) {
            const browserLanguage = 
                typeof navigator !== 'undefined' ? navigator.language.split('-')[0] : null;

            console.log(browserLanguage)
    
            const defaultLanguage = browserLanguage || DEFAULT_LANG || 'es';

            
            this.setLanguage(defaultLanguage);
            return defaultLanguage;
        }
    
        return locale;
    }    

    static setLanguage(locale){
        window.localStorage.removeItem('LANG');
        window.localStorage.setItem('LANG', locale);
    }
}

export { CommonService }
